export const START_CONNECTION = 'START_CONNECTION';
export const START_CONNECTION_SUCCESS = 'START_CONNECTION_SUCCESS';
export const START_CONNECTION_ERROR = 'START_CONNECTION_ERROR';

export const LOST_CONNECTION = 'LOST_CONNECTION';

export const SET_LOCATION = 'SET_LOCATION';

export const INCOMING_MESSAGE = 'INCOMING_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_USERNAME_SUCCESS = 'SET_USERNAME_SUCCESS';
export const SET_USERNAME_ERROR = 'SET_USERNAME_ERROR';

export const GAME_GET_LIST = 'GAME_GET_LIST';
export const GAME_GET_LIST_SUCCESS = 'GAME_GET_LIST_SUCCESS';
export const GAME_GET_LIST_ERROR = 'GAME_GET_LIST_ERROR';

export const CREATE_GAME = 'CREATE_GAME';
export const CREATE_GAME_SUCCESS = 'CREATE_GANE_SUCCESS';
export const CREATE_GAME_ERROR = 'CREATE_GAME_ERROR';

export const GAME_JOIN_AS_PLAYER = 'GAME_JOIN_AS_PLAYER';

export const GAME_FULL_STATUS = 'GAME_FULL_STATUS';
export const GAME_PLAYER_TOKEN =  'GAME_PLAYER_TOKEN';
export const BOARD_STRUCTURE = 'BOARD_STRUCTURE';

export const GAME_START = 'GAME_START';
export const GAME_ROLL_DICES_CONFIRM = 'GAME_ROLL_DICES_CONFIRM';
export const GAME_ROLL_DICES = 'GAME_ROLL_DICES';
export const GAME_ROLL_DICES_RESULT = 'GAME_ROLL_DICES_RESULT';

export const GAME_DIALOGBOX = 'GAME_DIALOGBOX';
export const GAME_DIALOGBOX_RESPONSE = 'GAME_DIALOGBOX_RESPONSE';

export const GAME_FINISH_MOVEMENT = 'GAME_FINISH_MOVEMENT';
export const GAME_ALLOW_TO_FINISH_MOVEMENT = 'GAME_ALLOW_TO_FINISH_MOVEMENT';

export const LEAVE_GAME = 'LEAVE_GAME';

export const SET_ERROR = 'SET_ERROR';

export const setToken = (token: string) => ({
    type: SET_TOKEN,
    token
});

export const setError = (msg: string) => ({
    type: SET_ERROR,
    msg,
})

export const lostConnection = (state: boolean) => ({
    type: LOST_CONNECTION,
    state,
})

export const setUsername = (value: string) => ({
    type: SET_USERNAME,
    value
});

export const setUsernameSuccess = (username: string) => ({
    type: SET_USERNAME_SUCCESS,
    username
});

export const setUsernameError = (msg: string) => ({
    type: SET_USERNAME_ERROR,
    msg
});




