import React from 'react';
import logo from '../assets/images/logo.svg';

const ErrorPage = () => {
    return (<div className="ErrorPage">
        <img src={logo} className="Login__logo" alt="COSMOpoly" />
        BLAD
    </div>)
}

export default ErrorPage;
