import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import './assets/styles/App.scss';
import ProtectedRoute from './helpers/ProtectedRoute';
import {rootPath} from "./helpers/constants";
import {RootState} from "./redux/store";
import ErrorPage from "./views/ErrorPage";
import DebugRouter from "./views/DebugRouter";
import {startConnection} from "./redux/app/actions";
import AppLayout from "./views/app/AppLayout";
import {Root} from "react-dom/client";


const ViewApp = React.lazy(() => import('./views/app'));
const ViewLogin = React.lazy(() => import('./views/login'));

const selectSessId = (state: RootState) => state.app.sessId
const selectUsername = (state: RootState) => state.app.username;
const selectConnected = (state: RootState) => state.app.connected;
const selectLoading = (state: RootState) => state.app.loading;

const App = () => {
    const dispatch = useDispatch();
    const location = useSelector((state: RootState) => state.app.location);
    const sessId = useSelector(selectSessId)
    const username = useSelector(selectUsername)
    const connected = useSelector(selectConnected);
    const loading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(startConnection());
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        console.log(location);
        if(location)
            navigate(location);
    }, [location]);

    return (
        <AppLayout>
            <Routes>
                <Route path={rootPath} element={<ProtectedRoute path={rootPath} component={ViewApp} sessId={sessId} username={username} />} />
                <Route path="/login" element={(!username ? <ViewLogin  /> : <Navigate to={rootPath} />)} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="*" element={<Navigate to={rootPath} replace />} />
            </Routes>
        </AppLayout>
    );
}

export default App;

