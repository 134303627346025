import React  from 'react';
import {Route, Navigate} from 'react-router-dom';


interface Props {
    sessId: string|null;
    username: string|null;
    path: string;
    component: React.ComponentType<any>;
}

const ProtectedRoute = ({
    sessId,
    username,
    component: Component,
    path
}: Props) => {
    console.log('sessOd');
    return sessId && username ? (
        <Component />
    ) : (
        <Navigate
            to="/login"
            state={{ from: path }}
            replace
        />
    )
}


export default ProtectedRoute;
