import {
    SET_LOCATION,
    START_CONNECTION,
    START_CONNECTION_ERROR,
    START_CONNECTION_SUCCESS
} from '../actions';

export const startConnection = () => ({
    type: START_CONNECTION,
})

export const startConnectionSuccess = (socket: WebSocket) => ({
    type: START_CONNECTION_SUCCESS,
    socket,
});

export const startConnectionError = () => ({
    type: START_CONNECTION_ERROR
});

export const setLocation = (location: string) => ({
    type: SET_LOCATION,
    location
})


