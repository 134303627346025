import React, {PropsWithChildren} from 'react';

interface Props extends PropsWithChildren{
    onClose: () => void;
}

const Modal = ({ children, onClose }: Props) => {

    return (
        <div className="Modal" onClick={onClose} >
            <div className="ModalBody">
                {children}
            </div>
        </div>
        )

}

export default Modal;
