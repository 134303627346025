import {
    LOST_CONNECTION, SET_ERROR,
    SET_TOKEN, SET_USERNAME, SET_USERNAME_ERROR, SET_USERNAME_SUCCESS,
    START_CONNECTION,
    START_CONNECTION_ERROR,
    SET_LOCATION,
    START_CONNECTION_SUCCESS
} from '../actions';

const INIT_STATE = {
    connected: false,
    connectionLost: false,
    socket: null,
    loading: false,
    sessId: '',
    username: '',
    usernameLoading: false,
    usernameSetting: false,
    usernameError: '',
    location: '',
    error: '',
}

const appReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case SET_LOCATION:
            return {
                ...state,
                location: action.location,
            };
        case START_CONNECTION:
            return {
                ...state,
                loading: true,
            };

        case LOST_CONNECTION:
            return {
                ...state,
                connectionLost: action.state
            }

        case START_CONNECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                connected: true,
                socket: action.socket
            };

        case START_CONNECTION_ERROR:
            return {
                ...state,
                loading: false,
                connected: false,
            };
        case SET_TOKEN:
            return {
                ...state,
                sessId: action.token
            };

        case SET_USERNAME:
            return {
                ...state,
                usernameSetting: true,
            };

        case SET_USERNAME_SUCCESS:
            return {
                ...state,
                usernameLoading: false,
                usernameSetting: false,
                usernameError: '',
                username: action.username
            };

        case SET_USERNAME_ERROR:
            return {
                ...state,
                usernameLoading: false,
                usernameSetting: false,
                usernameError: action.msg,
                username: ''
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.msg,
            }


        default:
            return state;
    }
};

export default appReducer;
