import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  INCOMING_MESSAGE, LOST_CONNECTION, SEND_MESSAGE, SET_USERNAME,
  START_CONNECTION
} from '../actions';

import {
  setLocation,
  startConnectionError,
  startConnectionSuccess
} from './actions';

import createConnection, {sendMessage} from '../../helpers/ws';

import { wsUrl} from '../../constants/defaultValues';

import {
  Connection,
  ConnectionStatus, IncomingMessageAction,
  SendMessageAction, SetUserNameAction,
  WSMessage,
} from "../../types";
import store from "../store";
import parseMessage from "../../helpers/messages";

export function* watchStartConnection() {
  yield takeEvery(START_CONNECTION, startConnection);
}


export function* watchSetUsername() {
  yield takeEvery(SET_USERNAME, setUsername);
}

export function* watchIncomingMessage() {
  yield takeEvery(INCOMING_MESSAGE, incomingMessage);
}

export function* watchSendMessage() {
  yield takeEvery(SEND_MESSAGE, sendWsMessage);
}

function* startConnection() {
  try {
    const { sock, status } : Connection = yield call(createConnection, {
      url: wsUrl,
      onMessage: function (data: WSMessage) {
        store.dispatch({
          type: INCOMING_MESSAGE,
          data,
        })
      },
      onClose: function() {
        store.dispatch({
          type: LOST_CONNECTION,
          state: true,
        })
      }
    });

    if(status === ConnectionStatus.Connected) {
      yield put(startConnectionSuccess(sock));
    }
  } catch (error) {
    console.log(error);
    yield put(startConnectionError());
    yield put(setLocation('/error'))
  /*  history.push('/error', {
      code: 1
    })*/
  }
}

function incomingMessage(action: IncomingMessageAction) {
  const { data } = action;

  parseMessage(data)
}

function setUsername(action: SetUserNameAction) {
  const { value } = action;

  sendMessage({
    action: 'USER_SET_USERNAME',
    data: value
  });
}

function* sendWsMessage(action: SendMessageAction) {
  const { data } = action;
  yield call(sendMessage, data);
}


// function* verifyUserFail() {
//   yield put(verifyFail());
// }
//
// const verifyUserAuthTokenAsync = async () =>
//   // eslint-disable-next-line no-return-await
//   await verify()
//     .then((user) => user)
//     .catch((error) => error);
//
// function* verifyUserAuthToken() {
//   try {
//     const user = yield call(verifyUserAuthTokenAsync);
//
//     if (user.id) {
//       const item = {
//         id: user.id,
//         title: `${user.firstName} ${user.lastName}`,
//         img: '/assets/img/profiles/l-1.jpg',
//         role: user.type,
//         country: user.country,
//       };
//       yield put(loginUserSuccess(item));
//     } else {
//       yield call(verifyUserFail);
//     }
//   } catch (error) {
//     yield call(verifyUserFail);
//   }
// }
//
// function* registerWithSocialSuccess({ payload }) {
//   try {
//     const { user, history } = payload;
//
//     if (user.id) {
//       const item = {
//         id: user.id,
//         title: `${user.firstName} ${user.lastName}`,
//         img: '/assets/img/profiles/l-1.jpg',
//         role: user.type,
//         country: user.country,
//       };
//       yield put(loginUserSuccess(item));
//       history.push(adminRoot);
//     } else {
//       yield call(verifyUserFail);
//     }
//   } catch (error) {
//     yield call(verifyUserFail);
//   }
// }
//
// export function* watchVerifyUser() {
//   yield takeEvery(VERIFY_USER, verifyUserAuthToken);
// }
//
// export function* watchRegisterWithSocialSuccess() {
//   yield takeEvery(REGISTER_WITH_SOCIAL_SUCCESS, registerWithSocialSuccess);
// }
//
// const loginWithEmailPasswordAsync = async (email, password, token) =>
//   // eslint-disable-next-line no-return-await
//   await signIn(email, password, token)
//     .then((user) => user)
//     .catch((error) => error);
//
// const loginWithGoogleAsync = async (email, token) =>
//   // eslint-disable-next-line no-return-await
//   await signInWithGoogle(email, token)
//     .then((user) => user)
//     .catch((error) => error);
//
// function* loginWithGoogle({ payload }) {
//   const { email, accessToken } = payload;
//   const { history } = payload;
//
//   try {
//     const googlePayload = yield call(loginWithGoogleAsync, email, accessToken);
//
//     if (googlePayload.authenticated) {
//       if (!googlePayload.user) {
//         history.push('/user/register', {
//           firstname: googlePayload.googleUser.givenName || '',
//           lastname: googlePayload.googleUser.familyName || '',
//           email: googlePayload.googleUser.email || '',
//         });
//       } else {
//         const item = {
//           id: googlePayload.user.id,
//           title: `${googlePayload.user.firstName} ${googlePayload.user.lastName}`,
//           img: '/assets/img/profiles/l-1.jpg',
//           role: googlePayload.user.type,
//         };
//         yield put(loginUserSuccess(item));
//         history.push(adminRoot);
//       }
//     } else {
//       yield put(loginUserError(t('login:login-google-error')));
//     }
//   } catch (error) {
//     yield put(loginUserError(t('login:login-error-desc')));
//   }
// }
//
// export function* watchLoginUserWithGoogle() {
//   yield takeEvery(LOGIN_USER_WITH_GOOGLE, loginWithGoogle);
// }
//
// const loginUserWithFacebookAsync = async (userid, token) =>
//   // eslint-disable-next-line no-return-await
//   await signInWithFb(userid, token)
//     .then((user) => user)
//     .catch((error) => error);
//
// function* loginWithFb({ payload }) {
//   const { userid, accessToken } = payload;
//   const { history } = payload;
//
//   try {
//     const fbPayload = yield call(
//       loginUserWithFacebookAsync,
//       userid,
//       accessToken
//     );
//
//     if (fbPayload.authenticated) {
//       if (fbPayload.user === null) {
//         const [firstname, lastname] = fbPayload.fbUser.name.split(' ');
//         history.push('/user/register', {
//           firstname: firstname || '',
//           lastname: lastname || '',
//           email: fbPayload.fbUser.email || '',
//         });
//       } else {
//         const item = {
//           id: fbPayload.user.id,
//           title: `${fbPayload.user.firstName} ${fbPayload.user.lastName}`,
//           img: '/assets/img/profiles/l-1.jpg',
//           role: fbPayload.user.type,
//         };
//         yield put(loginUserSuccess(item));
//         history.push(adminRoot);
//       }
//     } else {
//       yield put(loginUserError(t('login:login-google-error')));
//     }
//   } catch (error) {
//     yield put(loginUserError(t('login:login-error-desc')));
//   }
// }
//
// export function* watchLoginUserWithFb() {
//   yield takeEvery(LOGIN_USER_WITH_FACEBOOK, loginWithFb);
// }
//
// function* loginWithEmailPassword({ payload }) {
//   const { email, password } = payload.user;
//   const { history } = payload;
//   try {
//     const token = yield call(getToken, 'login');
//     const loginUser = yield call(
//       loginWithEmailPasswordAsync,
//       email,
//       password,
//       token
//     );
//     if (loginUser.user) {
//       const item = {
//         id: loginUser.user.id,
//         title: `${loginUser.user.firstName} ${loginUser.user.lastName}`,
//         img: '/assets/img/profiles/l-1.jpg',
//         role: loginUser.user.type,
//       };
//       yield put(loginUserSuccess(item));
//       history.push(adminRoot);
//     } else {
//       yield put(loginUserError(t('login:user.login-error')));
//     }
//   } catch (error) {
//     yield put(loginUserError(t('login:login-error-desc')));
//   }
// }
//
// export function* watchLogoutUser() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(LOGOUT_USER, logout);
// }
//
// const logoutAsync = async () =>
//   // eslint-disable-next-line no-return-await
//   await signOut()
//     .then((success) => success)
//     .catch((error) => {
//       return error;
//     });
//
// export function* logout({ payload }) {
//   const { history } = payload;
//
//   try {
//     const signedOut = yield call(logoutAsync);
//     console.dir(signedOut);
//     if (!signedOut) {
//       yield put(logoutUserError(t('login:logout-error')));
//     }
//     history.push(adminRoot);
//   } catch (error) {
//     yield put(logoutUserError(t('login:logout-error')));
//   }
// }
//
// export function* watchForgotPassword() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(FORGOT_PASSWORD, forgotPassword);
// }
//
// const forgotPasswordAsync = async (email, token) =>
//   // eslint-disable-next-line no-return-await
//   await sendResetPassword(email, token)
//     .then((user) => user)
//     .catch((error) => error);
//
// function* forgotPassword({ payload }) {
//   const { email } = payload.forgotUserMail;
//   try {
//     const token = yield call(getToken, 'forgot');
//     const forgotPasswordStatus = yield call(forgotPasswordAsync, email, token);
//     if (forgotPasswordStatus) {
//       yield put(forgotPasswordSuccess('success'));
//     } else {
//       yield put(forgotPasswordError(forgotPasswordStatus.message));
//     }
//   } catch (error) {
//     yield put(forgotPasswordError(error));
//   }
// }
//
// export function* watchResetPassword() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(RESET_PASSWORD, resetPassword);
// }
//
// const resetPasswordAsync = async (resetPasswordCode, newPassword, token) => {
//   // eslint-disable-next-line no-return-await
//   return await confirmPasswordReset(resetPasswordCode, newPassword, token)
//     .then((user) => user)
//     .catch((error) => error);
// };
//
// function* resetPassword({ payload }) {
//   const { newPassword, resetPasswordCode } = payload;
//   try {
//     const token = yield call(getToken, 'reset');
//     const resetPasswordStatus = yield call(
//       resetPasswordAsync,
//       resetPasswordCode,
//       newPassword,
//       token
//     );
//     if (resetPasswordStatus) {
//       yield put(resetPasswordSuccess('success'));
//     } else {
//       yield put(resetPasswordError(resetPasswordStatus.message));
//     }
//   } catch (error) {
//     yield put(resetPasswordError(error));
//   }
// }

// export default function* rootSaga() {
//   yield all([
//     fork(watchLoginUser),
//     fork(watchLogoutUser),
//     fork(watchForgotPassword),
//     fork(watchResetPassword),
//     fork(watchVerifyUser),
//     fork(watchLoginUserWithGoogle),
//     fork(watchLoginUserWithFb),
//     fork(watchRegisterWithSocialSuccess),
//   ]);
// }

export default function* rootSaga() {
  yield all([
    fork(watchStartConnection),
    fork(watchSendMessage),
    fork(watchIncomingMessage),
    fork(watchSetUsername)
  ]);
}
