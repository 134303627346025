import {Action} from "redux";



export interface GetGameListSuccessAction extends Action {
    history: History
}

export interface GameDialogBoxResponseAction extends Action {
    dialogType: DialogBoxOptionType,
    token: DialogBox['dialogbox_token']
}

export interface SendMessageAction extends Action {
    data: WSMessageData
}

export interface IncomingMessageAction extends Action {
    data: WSMessage,
    history: History
}

export interface SetUserNameAction extends Action {
    value: string;
}

export interface GameJoinAsPlayerAction extends Action {
    gameToken: string;
}

export interface ErrorCodes {
    [key: number]: string,
}

export enum ConnectionStatus {
    'Connected' = 1
}

export interface Connection {
    status: ConnectionStatus,
    sock: WebSocket
}

export interface WSMessageData {
    action: string,
    data?: any
}

export enum WsMessageStatus {
    IN_GAME = "IN_GAME",
    SPECTATOR = "SPECTATOR",
    IDLE = "IDLE",
}

export enum WsMessageLocation {
    LOBBY = "LOBBY",
    GAME = "GAME"
}

export interface WsMessageItem {
    type: WsMessageItemType,
    data: any
}

export enum GameStatus {
    WAITING = "WAITING",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
    STOPPED = "STOPPED",
}

export interface GameModel {
    game_token: string,
    status: GameStatus,
    number: number,
    min_players: number,
    max_players: number,
    players: string[],
}

export enum BuildingType {
    HOTEL = "HOTEL",
    HOUSE = "HOUSE",
}

export interface Building {
    type: BuildingType,
    amount: number
}

export interface GameBuildings {
    [key: string]: Building
}

export interface PropertyRelations {
    [key: string]: string
}

export interface GamePlayer {
    player_token: string,
    username: string,
    balance: string|null,
    avoid_prison_cards_amount: number,
    position: number|null,
    prison: boolean,
    time_in_prison_queue: null,
}

export interface GameFullStatus {
    status: GameStatus,
    player_token: string,
    game_token: string,
    players: GamePlayer[],
    owner_player_token: string,
    property_relations: PropertyRelations,
    buildings: GameBuildings,
    pledges: number[],
    current_player_movement_token: string|null,
}

export interface GameDicesResult {
    dice1: number;
    dice2: number;
    current_player_movement_token: string;
}

export interface GroupsNames {
    [key: string]: string,
}

export enum StructureFieldType {
    START = "START",
    STANDARD = "STANDARD",
    BLUE_CHANCE = "BLUE_CHANCE",
    RED_CHANCE = "RED_CHANCE",
    PRISON = "PRISON",
    SPECIAL = "SPECIAL",
    DIRECTIONAL = "DIRECTIONAL",
    GO_TO_PRISON = "GO_TO_PRISON",
    PAYMENT = "PAYMENT",
}

export interface StructureFieldPayment {
    empty?: number,
    houses: {
        [key: string]: number,
    },
    hotel: number
    directionals: {
        [key: string]: number,
    }
}

export interface StructureField {
    name: string,
    type:StructureFieldType,
    group_id?: number,
    price?: number,
    pledge?: number,
    house_price?: number,
    hotel_price?: number,
    payment?: number,
    payments?: StructureFieldPayment,

}

export interface FieldColors {
    [key: number]: string,
}

export interface Structure {
    [key: string]: StructureField,
}

export interface Board {
    name: string;
    start_balance: number;
    start_position: number;
    movement_through_start_payment: number,
    number_of_turns_in_prison: number,
    prison_position: number,
    currency: string,
    groups_names: GroupsNames,
    structure: Structure
}

export enum DialogBoxType {
    BUY_PROPERTY = "BUY_PROPERTY",
    RED_CHANCE_CARD = "RED_CHANCE_CARD",
    BLUE_CHANCE_CARD = "BLUE_CHANCE_CARD",
    BANKRUPT = "BANKRUPT",
    PAYMENT = "PAYMENT",
}

export interface DialogBoxOption {
    type: DialogBoxOptionType,
    button_message: string,
    enabled: boolean,
    time_limit: number,
}

export enum DialogBoxOptionType {
    YES = "YES",
    NO = "NO",
    PAY = "PAY",
    GO = "GO",
    AVOID_PRISON = "AVOID_PRISON",
    TAKE = "TAKE",
}

export interface DialogBox {
    dialogbox_token: string,
    type: DialogBoxType,
    title: string,
    description: string,
    options: DialogBoxOption[]
}

export enum WsMessageItemType {
    LOCATION = 'LOCATION',
    TOKEN = "TOKEN",
    USERNAME = "USERNAME",
    USERNAME_EXIST = "USERNAME_EXIST",
    USERNAME_UPDATED = "USERNAME_UPDATED",
    GAME_LIST = "GAME_LIST",
    ERROR = "ERROR",
    PLAYERS_LIST = "PLAYERS_LIST",
    BOARD_LIST = "BOARD_LIST",
    GAME_FULL_STATUS = "GAME_FULL_STATUS",
    BOARD_STRUCTURE = "BOARD_STRUCTURE",
    GAME_ROLL_DICES = "GAME_ROLL_DICES",
    GAME_ROLL_DICES_RESULT = "GAME_ROLL_DICES_RESULT",
    GAME_DIALOGBOX = "GAME_DIALOGBOX",
    GAME_PLAYER_TOKEN = "GAME_PLAYER_TOKEN",
    GAME_ALLOW_TO_FINISH_MOVEMENT = "GAME_FINISH_MOVEMENT",
}

export interface WSMessage {
    status: WsMessageStatus,
    location: WsMessageLocation,
    items : WsMessageItem[]
}
